<template>
  <span
    v-if="finalPrice.discount_type == 'percentage' && finalPrice.discount != 0"
    class="recommended-discount  fontsize-mini text-white px-2 bg-color-discount"
    >{{ finalPrice.discount }}% تخفیف</span
  >
  <span
    v-else-if="finalPrice.discount_type == 'flat'&& finalPrice.discount != 0"
    class="recommended-discount  fontsize-mini text-white px-2 bg-color-discount"
  >
    <template v-if="!isMobile "> تخفیف :</template>
    {{ finalPrice.discount | price }} تومان
  </span>
</template>

<script>
export default {
  name: "DiscountTag",
  inject: ["mediaQueries"],
  props: {
    finalPrice: Object,
  },
  computed: {
    isMobile() {
      return this.mediaQueries.mobileSize;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .recommended-discount {
    font-size: 0.7rem;
  }
}
</style>
