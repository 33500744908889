<template>
  <article
    class="in-product position-relative"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <template v-if="product && product.major_final_price">
      <DiscountTag :final-price="product.major_final_price" />
    </template>
    <span v-if="checkFreeShipMent()" id="shipment_free">
      ارسال رایگان
    </span>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :class="{ compareBtnWithFreeShipping: checkFreeShipMent() == true }"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
      class="text-center"
    >
      <!--      <div class="recommended-products-img text-center" :style="!product ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)' : ''">-->
      <div class="recommended-products-img text-center">
        <img
          loading="lazy"
          v-if="product && mainImage"
          class=""
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
      </div>

      <div v-if="product" class="text-center position-relative">
        <div
          class="product-rate d-flex align-items-center justify-content-center"
        >
          <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
          <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
        </div>
        <h6
          class="text-color-444 weight-bold fontsize-medium mb-0 mt-2 mt-sm-3 prodctTitle"
        >
          {{ product.title }}
        </h6>
        <div v-if="product.status == 'available'">
          <span class="text-color-444 fontsize-medium mainPrice">
            {{ mainPrice | price }}
          </span>
          <span class="text-color-666 fontsize-small pr-1 mainPrice">تومان</span>
          <span v-if="discountAmount != 0" class="pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <span class="line-through text-color-999 fontsize-small oldPrice">{{
              (mainPrice + discountAmount) | price
            }}</span>
          </span>
        </div>
        <div v-else class="hide-by-hover ">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-color-themeRed fontsize14"
          >
            ناموجود
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3 px-4 px-sm-5">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </router-link>
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";

import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import DiscountTag from "./DiscountTag";
import Tick from "@/parts/Front/components/shared/Tick";
export default {
  name: "Product",
  components: { Tick, DiscountTag, BFormRating, BSkeleton },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImage,
    getProductInFlashes() {
      let products = [];
      this.homeData.flashes.forEach((flash) => {
        products.push(...flash.products);
      });
      return products;
    },
    checkFreeShipMent() {
      if (this.$route.path == "/") {
        if (
          this.product &&
          this.product.pivot &&
          this.product.pivot.shipment_free == 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.homeData != null) {
          if (this.homeData.flashes.length) {
            let res = false;

            let flashesProduct = this.getProductInFlashes();
            console.log(flashesProduct);
            if (flashesProduct.length) {
              flashesProduct.forEach((fp) => {
                console.log(fp.id);
                console.log(this.product.id);
                if (fp.id == this.product.id) {
                  res = true;
                  console.log("inja");
                }
              });
            }
            return res;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.price : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>

<style scoped>
.mainPrice{
  color: var(--newTheme-color);
}
.oldPrice{
  color: #808080;
}
.in-product {
  background-color: white;
}
@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}

.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}

.compare-btn {
  z-index: 2;
  position: absolute;
  left: 3px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}
.compare-btn.compareBtnWithFreeShipping {
  top: 30px;
}
.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}

.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}
.prodctTitle {
  width: 250px;
  height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px !important;
  margin: auto;
}
@media (max-width: 500px) {
  .prodctTitle {
    width: 150px;
  }
}
.py-25 {
  padding: 14px 0;
}
span#shipment_free {
  position: absolute;
  left: 0;
  background: green;
  z-index: 10000;
  color: white;
  padding: 5px 10px;
  top: 0;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 13px;
}
</style>
